
import 'swiper/css/swiper.min.css'
import Swiper from 'swiper'
export default{
  name: 'ProductFeaturesSliderSlice',
  props: ['slice'],
  data() {
    return {
      id: `${this.slice.id.replace('$','')}-slider`,
      swiper: null,
      isLoading: true
    }
  },
  computed: {
    slides(){
      return this.slice.items
    }
  },
  mounted() {
    const sliderId = '#' + this.id
    this.swiper = new Swiper(`${sliderId}`, {
      slidesPerView: '1',
      spaceBetween: 50,
      speed: 400,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      loop: true,
      watchOverflow: true,
      navigation: {
        nextEl: `${sliderId} .slider-navigation-next`,
        prevEl: `${sliderId} .slider-navigation-prev`
      }
    })
  },
}
